<template>
    <div>
        <nav-bar title="订单详情" url="-1"></nav-bar>

        <!--用户详情-->
        <div class="top">
            <div class="name">
                订单详情
            </div>
            <div class="list">
                <div class="left">姓名</div>
                <div class="right">{{info.name}}</div>
            </div>
            <div class="list">
                <div class="left">身份证号</div>
                <div class="right">{{info.sfz}}</div>
            </div>
            <div class="list">
                <div class="left">地址</div>
                <div class="right">{{info.address}}</div>
            </div>
            <div class="list">
                <div class="left">手机号</div>
                <div class="right">{{info.tel}}</div>
            </div>
            <div class="list">
                <div class="left">办卡类型</div>
                <div class="right">{{info.cate.cate_name}}</div>
            </div>
            <div class="list">
                <div class="left">支付金额</div>
                <div class="right">￥{{info.money}}元</div>
            </div>
            <div class="list">
                <div class="left">订单状态</div>
                <div class="right">
                    <van-tag mark type="danger" v-if="info.state==-1">订单取消</van-tag>
                    <van-tag mark v-if="info.state==1">待支付</van-tag>
                    <van-tag mark type="success" v-if="info.state==2">待审核</van-tag>
                    <van-tag mark type="danger" v-if="info.state==4">审核不通过</van-tag>
                    <van-tag mark type="warning" v-if="info.state==5">异常退款</van-tag>
                    <van-tag mark v-if="info.state==6">待审核</van-tag>
                    <van-tag mark type="danger" v-if="info.state==7">审核不通过</van-tag>
                    <van-tag mark type="success" v-if="info.state==8">激活成功</van-tag>
                    <van-tag mark type="danger" v-if="info.state==9">退款失败</van-tag>
                    <van-tag mark type="warning" v-if="info.state==10">自动激活中</van-tag>
                </div>
            </div>
        </div>

        <!-- 订单信息 -->
        <div class="top">
            <div class="list">
                <div class="left">订单号</div>
                <div class="right">{{info.order_id}}</div>
            </div>
            <div class="list">
                <div class="left">下单时间</div>
                <div class="right">{{info.create_time}}</div>
            </div>
            <div class="list" v-if="info.pay_time">
                <div class="left">支付时间</div>
                <div class="right">{{info.pay_time}}</div>
            </div>
            <div class="list" v-if="info.examine_time">
                <div class="left">激活时间</div>
                <div class="right">{{info.examine_time}}</div>
            </div>
            <div class="list" v-if="info.refund_time">
                <div class="left">退款时间</div>
                <div class="right">{{info.refund_time}}</div>
            </div>
            <div class="list" v-if="info.examine_content">
                <div class="left">审核内容</div>
                <div class="right">{{info.examine_content}}</div>
            </div>
        </div>

        <!-- 快递信息 -->
        <div class="top" v-if="info.cate_id == 1">
            <div class="name">
                快递信息
            </div>
            <div class="list">
                <div class="left">收货方式</div>
                <div class="right">{{info.card_is_express==1?'快递递送':'门店自提'}}</div>
            </div>

            <div class="list" v-if="info.card_is_express == 1">
                <div class="left">收货地址</div>
                <div class="right" style="display: inline-block;">{{info.card_address}}</div>
            </div>
            <div class="list" v-if="info.card_is_express == 1">
                <div class="left">快递费</div>
                <div class="right">{{info.card_express_money}}元</div>
            </div>

            <div class="list" v-if="info.card_is_express == 1">
                <div class="left">发货状态</div>
                <div class="right">{{info.card_express_state==1?'已发货':'待发货'}}</div>
            </div>

            <div class="list" v-if="info.card_express_no !== '0' && info.card_is_express == 1">
                <div class="left">快递单号</div>
                <div class="right">{{info.card_express_no}}</div>
            </div>
        </div>

        <!-- 身份照片 -->
        <div class="top">
            <div class="order-photo">
                <van-row>
                    <van-col span="8" v-if="info.photo_sfz_1" @click="preview([info.photo_sfz_1])">
                        <van-image class="order-image" fit="cover" :src="info.photo_sfz_1">
                        </van-image>
                        <p>身份证正面</p>
                    </van-col>

                    <van-col span="8" v-if="info.photo_sfz_2" @click="preview([info.photo_sfz_2])">
                        <van-image class="order-image" fit="cover" :src="info.photo_sfz_2"></van-image>
                        <p>身份证背面</p>
                    </van-col>
                    <van-col span="8" v-if="info.photo_person" @click="preview([info.photo_person])">
                        <van-image class="order-image" fit="cover" :src="info.photo_person"></van-image>
                        <p>办理人照片</p>
                    </van-col>

                    <van-col span="8" v-if="info.user_nianka_photo && info.state == 8"
                        @click="preview([info.user_nianka_photo])">
                        <van-image class="order-image" fit="cover" :src="info.user_nianka_photo">
                        </van-image>
                        <p>电子年卡照片</p>
                    </van-col>

                    <van-col span="8" v-if="info.photo_book" @click="preview([info.photo_book])">
                        <van-image class="order-image" fit="cover" :src="info.photo_book">
                        </van-image>
                        <p>持卡人户口本</p>
                    </van-col>
                </van-row>
            </div>
        </div>


        <!-- 其他资料 -->
        <div class="top" v-if="info.photo_other.length">
            <div class="name">其他资料</div>
            <div class="order-photo">
                <van-row>
                    <van-col span="8" v-for="(item,index) in info.photo_other" :key="index">
                        <van-image @click="preview(info.photo_other,index)" width="90%" height="60px" fit="cover"
                            :src="item+ '!thumbnail'"></van-image>
                    </van-col>
                </van-row>
            </div>
        </div>

        <!-- 操作按钮 -->
        <div class="action">
            <div v-if="info.state==1 && is_company==0">
                <van-button type="primary" round @click="submit" :color="config.main_color" size="large">立即支付
                </van-button>
                <van-button type="primary" round @click="cancel_order" color="#ccc" size="large"
                    style="margin-top: 15px;">
                    取消订单
                </van-button>
            </div>

            <div style="margin-top: 10px;" v-if="is_company==0 && info.state!==2 && info.sale_id!==17756">
                <van-button type="primary" round @click="url2('/?user_id=' + info.id)" :color="config.main_color"
                    size="large">
                    <span v-if="info.state==8">立即续期</span>
                    <span v-if="info.state!==8 && info.state!==2 ">重新办理</span>
                </van-button>
            </div>
        </div>


    </div>
</template>

<script>
    var jweixin = require('jweixin-module')
    import { card_order_detail, card_pay_cancel, card_pay_submit} from '@/api/card.js';
    import { ImagePreview, Dialog, Toast } from 'vant';
    import { wxPay } from '@/static/js/pay.js';
    export default {
        name: 'index',
        data() {
            return {
                is_company: 0,
                info: {
                    cate: {},
                    photo_other: [],
                },
                isMiniProgram:false
            }
        },
        created() {
            this.is_company = this.$route.query.is_company ? this.$route.query.is_company : 0;
            this.get_data();
        },
        methods: {
            get_data() {
                card_order_detail({
                    id: this.$route.query.id,
                    thumbnail: 1,
                    loading:true,
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },

            //图片预览
            preview(img, index) {
                var imgs = [];
                if(img.length > 2){
                    for(var item of img){
                        imgs.push(item + '!w1000');
                    }
                }else{
                    imgs = img;
                }
                ImagePreview({
                    images: imgs,
                    startPosition: index ? index : 0
                });
            },

            //取消订单
            cancel_order() {
                var that = this;
                Dialog.confirm({
                    title: '取消确认',
                    message: '确认要取消该订单吗？取消后不可以恢复！',
                }).then(function () {
                    card_pay_cancel({
                        loading: true,
                        loading_text: '取消中……',
                        id: that.$route.query.id,
                    }).then(res => {
                        if (res.code == 1) {
                            Toast(res.msg);
                            that.get_data();
                        }
                    });
                });
            },


            //提交支付
            submit() {
                    card_pay_submit({
                        id: this.$route.query.id,
                        loading: true,
                        loading_text: '支付中……',
                    }).then(res => {
                        if (res.code == 1) {
                            if(res.data.info.sale_id == 17756){
                                console.log(17756)
                                let payDataStr = encodeURIComponent(JSON.stringify(res.data.info));//因为要吧参数传递给小程序，所以这里需要转为字符串
                                const url = `/newPages/pages/wxpay/wxPay?payDataStr=${payDataStr}`;
                                jweixin.miniProgram.redirectTo({
                                    url: url,
                                    fail:(err)=>{
                                        Dialog.alert({
                                            message: JSON.stringify(err),
                                        });
                                    }
                                });
                            }
                            else{
                                wxPay(res.data.info).then(res2 => {
                                    Toast(res.msg);
                                    this.get_data();
                                }).catch(err => {
                                    this.get_data();
                                });
                            }
                        }
                    });
            },
            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },

            url2(path){
                window.location.href = path;
            },
        }
    }
</script>

<style scoped>
    .order-image {
        width: 90%;
        height: 60px;
    }

    .top {
        width: calc(100% - 40px);
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }

    .top .name {
        height: 40px;
        line-height: 40px;
        padding-left: 20px;
        padding-right: 10px;
        font-weight: 800;
        font-size: 15px;
        width: calc(100% - 40px);
        position: relative;
        color: #060920;
    }

    .top .name img {
        height: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .top .name span {
        position: absolute;
        left: 35px;
    }


    .top .name::after {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: 0 0;
        border-bottom: 1px solid #f2f2f2;
        pointer-events: none;
    }

    .top .list {
        position: relative;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 200;
        font-size: 13px;
        width: calc(100% - 20px);
    }

    .top .list .left {
        position: absolute;
        left: 10px;
        display: inline-block;
        font-weight: 700;
        color: #060920;
    }

    .top .list .right {
        position: absolute;
        right: 10px;
        display: inline-block;
        color: #060920;
        font-weight: 400;
        width: calc(100% - 70px);
        white-space: nowrap;
        text-align: right;
        height: 40px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .top .list::after {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: 0 0;
        border-bottom: 1px solid #f2f2f2;
        pointer-events: none;
    }

    .top .list:last-child::after {
        border: none;
    }


    .photo {
        width: calc(100% - 20px) !important;
        margin: 0 auto;
        background: #fff;
        text-align: center;
    }

    .order-photo {
        padding: 15px;
        background: #fff;
    }

    .order-photo .van-col {
        text-align: center;
    }

    .order-photo .van-col p {
        font-size: 14px;
        color: #060920;
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    .van-button--primary {
        background-image: url('../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }
</style>